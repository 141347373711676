import type { CustomColumnExtended } from "@/components/Datatable/Types";
import { useTableCase } from "@/hooks/UseTableCase";
import { useGetBookAdditonalFields } from "@/hooks/useGetBookAdditonalFields";
import { useGetRsvpData } from "@/hooks/useGetRsvpData";
import { deleteRsvp } from "@/services/api/deleteRsvpService";
import type { Booking } from "@/services/openapi/v1";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { addHours, format, parseISO } from "date-fns";
import type { TableInstance } from "react-table";
import type { BookAdditonalFields } from "@/services/api/getBookAdditonalFields";
import { FULL_DATE_FORMAT_ID } from "@/shared/constants";

interface Return {
  data: {
    rsvpData: Booking[];
    isLoading: boolean;
    tableInstance: TableInstance<any>;
    tableColumns: BookAdditonalFields;
    totalData: number;
  };
  method: {
    handleDeleteButton(rsvpId: string): Promise<void>;
    setTableState: React.Dispatch<
      React.SetStateAction<{
        pageIndex: number;
        pageSize: number;
      }>
    >;
  };
}

export default function useViewModel(): Return {
  const [tableState, setTableState] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const {
    data: rsvp,
    isLoading: isLoadingRsvp,
    refetch: refetchRsvp,
  } = useGetRsvpData(tableState.pageSize, tableState.pageIndex, {
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const initialColumns: CustomColumnExtended[] = useMemo(() => {
    const aggregate = rsvp?.data?.aggregate || {};
    return [
      {
        accessor: "name",
        Header: "Name",
        disableSortBy: false,
        Footer: "",
      },
      {
        accessor: "guest_total",
        Header: "Guest Total",
        disableSortBy: false,
        Footer: `Total: ${aggregate.total_guest || 0}`,
      },
      {
        Header: "Confirmation Attendance",
        accessor: (row: Booking) => (row.is_attending ? "Yes" : "No"),
        id: "is_attending",
        disableSortBy: false,
        Footer: `Yes: ${aggregate.count_attendance?.yes || 0}, No: ${
          aggregate.count_attendance?.no
        }`,
      },
      {
        id: "created_at",
        accessor: (row: Booking) => {
          if (!row.created_at) return "";
          const createdDate = parseISO(row.created_at);
          // TODO: remove this hardcoded timezone after the backend has been fixed
          const zonedCreatedDate = addHours(createdDate, 7);
          return format(zonedCreatedDate, FULL_DATE_FORMAT_ID);
        },
        Header: "Created At",
        disableSortBy: false,
        Footer: "",
      },
    ];
  }, [rsvp]);

  const { data: additonalColumns = [], isLoading: isLoadingTableColumns } =
    useGetBookAdditonalFields({});

  const rsvpData = useMemo(() => {
    return rsvp ? rsvp.data.data : [];
  }, [rsvp]);

  const tableColumns: CustomColumnExtended[] = useMemo(() => {
    const initialColumnsWithoutLast = initialColumns.splice(
      0,
      initialColumns.length - 1
    );
    const initialColumnsLast = initialColumns.at(-1);
    return [
      ...initialColumnsWithoutLast,
      ...additonalColumns,
      initialColumnsLast,
    ];
  }, [rsvp]);

  const tableInstance = useTableCase({
    columns: tableColumns,
    data: rsvpData,
    initialState: {
      pageSize: tableState?.pageSize,
      pageIndex: 0,
    },
  });

  const {
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    setTableState({ pageIndex, pageSize });
  }, [pageIndex, pageSize]);

  const handleDeleteButton = useCallback(async (rsvpId: string) => {
    try {
      await deleteRsvp(rsvpId);
      refetchRsvp();
    } catch (error) {
      console.error(error);
    } finally {
      toast.success(`The RSVP has been deleted`);
    }
  }, []);

  const isLoading = isLoadingTableColumns || isLoadingRsvp;

  return {
    data: {
      rsvpData,
      isLoading,
      tableInstance,
      tableColumns,
      totalData: rsvp?.data?.metadata?.total,
    },
    method: { handleDeleteButton, setTableState },
  };
}
